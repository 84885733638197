<template>
  <div
    v-if="!isLoading"
    :class="[
      'exam',
      {
        'exam--disabled': endingExam,
        '--dark-mode': darkModeEnabled,
      },
    ]"
  >
    <ExamHeader
      :alert="alert"
      :disabled="!alert.success"
      :hide-menu-disabled="hideMenuDisabled"
      @end-time="endTime()"
      @finish="showReviewModal()"
      @open-timer="trackGtmEvent('VerCronômetro')"
      @pause="redirectToExams()"
      @toggleDarkMode="toggleDarkMode()"
    />

    <transition
      appear
      name="fade"
      tag="div"
    >
      <div class="exam-body">
        <router-view
          key="exam-item"
          :disabled="!alert.success"
          :item="item"
          @end-time="endTime()"
          @open-timer="trackGtmEvent('VerCronômetro')"
        />
      </div>
    </transition>

    <ExamFooter
      :disabled="!alert.success"
      @finish="showReviewModal()"
      @show-questions="trackQuestionsDropdownEvent($event)"
    />

    <TourExam
      v-if="isExamTourVisible"
      @next="nextStep()"
      @previous="previousStep()"
    />

    <ExamReviewModal
      v-if="isModalVisible"
      @close="isModalVisible = false"
      @end="endExamApplication($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ExamHeader from '@/components/ExamHeader'
import ExamFooter from '@/components/ExamFooter'
import ExamReviewModal from '@/components/ExamReviewModal'

import http from '@/api/http'
import urls from '@/utils/urls'

const TourExam = () => import('@/components/TourExam')
export default {
  name: 'Exam',

  components: {
    ExamHeader,
    ExamFooter,
    TourExam,
    ExamReviewModal,
  },

  props: {
    assessmentId: {
      type: [String, Number],
      required: true,
    },

    applicationId: {
      type: [String, Number],
      required: true,
    },

    examId: {
      type: [String, Number],
      required: true,
    },

    itemOrder: {
      type: [String, Number],
      default: () => 0,
    },
  },

  data() {
    return {
      isModalVisible: false,
      endingExam: false,
      supervisorAvailability: null,
      alert: {
        message: '',
        icon: '',
        success: true,
      },
      hasConnectivity: true,
      serviceAvailable: true,
      hideMenuDisabled: false,
      event: {
        category: 'ExecuçãoAvaliação',
        action: '',
        label: '',
      },
    }
  },

  computed: {
    ...mapGetters([
      'exam',
      'item',
      'assessment',
      'items',
      'applicationResponse',
      'onboarding',
      'isLoading',
      'darkModeEnabled',
    ]),

    serviceAvailableMessage() {
      return 'O serviço voltou a funcionar normalmente.'
    },

    serviceUnavailablleMessage() {
      return 'Nosso serviço encontra-se temporariamente indisponível. Aguarde alguns instantes e tente se conectar'
    },

    hasConnectivityMessage() {
      return 'Você está on-line novamente.'
    },

    hasNoConnectivityMessage() {
      return 'Você está off-line. Para continuar a prova, conecte-se à internet.'
    },

    isDiagnostica() {
      return this.assessment.typeSlug === 'diagnostica'
    },

    isExamTourVisited() {
      return JSON.parse(
        localStorage.getItem('examTourVisited') || 'false',
      )
    },

    isExamTourVisible() {
      return !this.isExamTourVisited && this.isDiagnostica
    },
    trackEventLabel() {
      const { shortName, product } = this.assessment

      return product === 'CORRECTOR' ? 'Minhas avaliacoes' : shortName
    },
  },

  created() {
    this.load()
  },

  beforeMount() {
    this.setDarkMode(false)
  },

  mounted() {
    // this.checkSupervisorAvailability()
    localStorage.setItem('examTourVisited', 'true')
  },

  beforeDestroy() {
    this.clearItems()
    clearInterval(this.supervisorAvailability)

    if (this.$tours.onboarding) {
      this.$tours.onboarding.stop()
    }
  },

  methods: {
    ...mapActions([
      'setItem',
      'getAssessment',
      'getExam',
      'endExam',
      'getApplicationResponse',
      'getItems',
      'clearItems',
      'setOnboarding',
      'showMainLoading',
      'hideMainLoading',
      'setDarkMode',
      'getDarkMode',
    ]),

    async load() {
      this.showMainLoading()

      this.getAssessment(this.assessmentId)
      this.getExam({
        assessmentId: this.assessmentId,
        examId: this.examId,
      })

      const applicationResponse = await this.getApplicationResponse({
        applicationId: this.applicationId,
        examId: this.examId,
      })

      const {
        completed, remainingTimeInSeconds, deadline,
      } = applicationResponse

      if (completed || (remainingTimeInSeconds <= 0 && deadline !== null)) {
        this.redirectToExamsResult()
      } else {
        await this.getItems(applicationResponse.id)
        const itemOrder = this.getNextItemOrder()
        this.setItem(itemOrder)
        if (this.isItemOrderInvalid()) {
          this.redirectToExamItem(itemOrder)
        }
      }
      this.hideMainLoading()
    },

    getNextItemOrder() {
      if (this.isItemOrderInvalid()) {
        const invalidItem = this.items
          .find((item) => item.answer.markedOption === -1)

        return invalidItem ? invalidItem.studentOrder : 1
      }

      return this.itemOrder
    },

    isItemOrderInvalid() {
      const order = this.itemOrder
      const isNumber = typeof order === 'number'

      return !isNumber || order <= 0 || order > this.items.length
    },

    redirectToExamItem(itemOrder) {
      const { assessmentId, examId } = this
      this.$router.replace({
        name: 'exam',
        params: {
          assessmentId,
          examId,
          itemOrder,
        },
      })
    },

    redirectToExams() {
      if (!this.alert.success) {
        return
      }
      this.$router.push({ name: 'assessmentExams' })
    },

    redirectToExamsResult() {
      this.$router.push({ name: 'assessmentResults' })
    },

    showReviewModal() {
      if (!this.alert.success) {
        return
      }
      this.isModalVisible = true
      this.trackGtmEvent('RevisãoQuestões')
    },

    endTime() {
      this.endingExam = true
      this.endExamApplication()
    },

    async endExamApplication(finalizedByStudent = false) {
      this.endingExam = true

      await this.endExam({
        applicationResponseId: this.applicationResponse.id,
        finalizedByStudent,
      })

      this.$router.replace({
        name: 'assessmentResults',
        params: {
          assessmentId: this.assessmentId,
          applicationId: this.applicationId,
        },
      })

      this.trackGtmEvent('AvaliaçãoFinalizada')
    },

    checkSupervisorAvailability() {
      const HEALTH_RATE = 5000

      this.supervisorAvailability = setInterval(async () => {
        try {
          const response = await http.get(`${urls.SUPERVISOR_API}/healthcheck`)
          const { status } = response.data
          const alertDelay = 3000

          this.alert.message = status === 'DOWN'
            ? this.serviceUnavailablleMessage
            : this.alert.message

          if (!this.hasConnectivity) {
            const message = this.hasConnectivityMessage

            this.alert = {
              message,
              icon: 'wifi',
              success: true,
            }

            setTimeout(() => {
              this.alert.message = ''

              return null
            }, alertDelay)

            this.hasConnectivity = true
          } else if (!this.serviceAvailable && status === 'UP') {
            const message = this.serviceAvailableMessage

            this.alert = {
              message,
              icon: '',
              success: true,
            }

            setTimeout(() => {
              this.alert.message = ''

              return null
            }, alertDelay)

            this.serviceAvailable = true
          }
        } catch (error) {
          if (error.response) {
            const message = this.serviceUnavailablleMessage

            this.alert = {
              message,
              icon: '',
              success: false,
            }
            this.hasConnectivity = true
            this.serviceAvailable = false
          } else {
            const message = this.hasNoConnectivityMessage
            this.alert = {
              message,
              icon: 'wifi-off',
              success: false,
            }
            this.hasConnectivity = false
          }
        }
      }, HEALTH_RATE)
    },

    nextStep(hideMenuDisabled) {
      const examMenu = document.querySelector('.popover__menu')

      this.hideMenuDisabled = hideMenuDisabled

      if (examMenu) {
        examMenu.focus()
      }
    },

    previousStep(hideMenuDisabled) {
      const examMenu = document.querySelector('.popover__menu')

      this.hideMenuDisabled = hideMenuDisabled

      if (examMenu) {
        examMenu.focus()
      }
    },
    trackGtmEvent(action) {
      this.$trackEvent({
        ...this.event,
        action,
        label: this.trackEventLabel,
      })
    },
    trackQuestionsDropdownEvent(isDropdownOpened) {
      if (isDropdownOpened) {
        this.trackGtmEvent('VerQuestões')
      }
    },
    toggleDarkMode() {
      this.setDarkMode(false)
    },
  },
}
</script>

<style lang="sass">
$dark-mode-background-color: #2D3848
$dark-mode-text-color: #C9CCCF
$dark-mode-lightest-text-color: #F7F7F7
$dark-mode-exam-header-color: #1A202C
$dark-mode-box-color: #3A4556
$dark-mode-selected-option-color: #4d8af0
$dark-mode-footer-background-color: #4A5568
$dark-mode-footer-border: 1px solid #666E75
$dark-mode-pagination-background-color: #53627B
$dark-mode-activeted-option-color: #999EA3
$dark-mode-review-answer-color: #455265
$dark-mode-review-marked-answer-color: #3B4351
$dark-mode-scratch-color: #C0D9F6
$gradient-parameters: 90deg, $dark-mode-box-color -6%, rgba(8, 9, 11, 0.5) 94%
$dark-mode-scratch-gradient: linear-gradient($gradient-parameters)

.exam
  background: #fafafa
  box-sizing: border-box

  &--disabled
    pointer-events: none

  .connectivity--online
    background: $color_success

  &-body
    height: 100%
    max-width: 860px
    margin: 0 auto
    padding-top: 25px
    padding-bottom: 120px
    box-sizing: border-box
    color: $color-ink

    &.--first-item
      padding-top: 160px

      +mq-s
        padding-top: 180px

    +mq-l
      max-width: calc(100vw - 48px)

    +mq-s
      max-width: calc(100vw - 32px)

  .sas-input__field.--large
    height: 44px

  &.--dark-mode
    background: $dark-mode-background-color

    .sas-button
      &.--secondary,
      &.--tertiary
        background-color: transparent
        box-shadow: none
        border: none
        color: $dark-mode-text-color!important

    .exam-header
      background: $dark-mode-exam-header-color

      &__title__assessment,
      &__title__exam
        color: $dark-mode-text-color

      &__switch-dark-mode
        margin: 0 11px 0 10px

      .exam-timer__content-timer
        color: $dark-mode-text-color
        border-color: $dark-mode-text-color

      .popover__menu,
      .actions__button
        background-color: $dark-mode-footer-background-color
        color: $color-white

    .exam-body
      .exam-item__header
        background: $dark-mode-background-color
        color: $dark-mode-lightest-text-color

      .exam-item__statement
        color: $dark-mode-lightest-text-color

      .exam-item__options
        .box,
        .item-alternative__body__letter
          background: $dark-mode-box-color

        .item-alternative__body__letter.is-selected
          background-color: $dark-mode-selected-option-color

        .item-alternative__body__letter
          color: $dark-mode-lightest-text-color

        .item-alternative__button
          background: $dark-mode-scratch-gradient

        .item-alternative__body__scratch
          border: 1px solid $dark-mode-scratch-color

    .exam-footer
      .exam-pagination,
      .exam-pagination--mobile__bar
        background-color: $dark-mode-footer-background-color
        border-top: $dark-mode-footer-border

      .exam-pagination__actions__page .sas-input__field
        background: $dark-mode-background-color
        color: $dark-mode-text-color

      .exam-pagination__pages__list
        .sas-dropdown-menu,
        .sas-dropdown-menu__item
          background: $dark-mode-pagination-background-color!important

        .exam-pagination__item
          border-radius: 8px
          color: $dark-mode-lightest-text-color
          border: 1px solid $dark-mode-lightest-text-color

          &--filled
            border: 1px solid $dark-mode-selected-option-color

          &--actived
            background: $dark-mode-activeted-option-color
            color: $color-white
            border: 1px solid $dark-mode-activeted-option-color

      .exam-pagination--mobile__pages
        background: $dark-mode-pagination-background-color

        .exam-pagination--mobile__item
          border-radius: 8px
          color: $dark-mode-lightest-text-color
          border: 1px solid $dark-mode-lightest-text-color

          &--filled
            border: 1px solid $dark-mode-selected-option-color
          &--actived
            background: $dark-mode-activeted-option-color
            color: $color-white
            border: 1px solid $dark-mode-activeted-option-color

    .review-modal .modal
      background-color: $dark-mode-background-color

      .modal__close
        color: $dark-mode-lightest-text-color

      .header__title,
      .header-info__description,
      .info-right__label,
      .info-right__text--custom-tag
        color: $dark-mode-text-color

      .review-modal__body-details
        .answer
          background-color: $dark-mode-review-answer-color

          .answer__order
            color: $dark-mode-lightest-text-color

          .answer__marked-option
            background: $dark-mode-review-marked-answer-color

          .answer--blank
            color: $dark-mode-lightest-text-color
            mix-blend-mode: normal

      .review-modal__footer
        border-top: $dark-mode-footer-border
        background: $dark-mode-footer-background-color

    .confirmation-modal .sas-modal
      background: $dark-mode-background-color!important

      .confirmation-modal__body
        color: $dark-mode-text-color
</style>
