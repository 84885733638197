<template>
  <div
    class="info"
    :class="{ 'info--no-icon': !icon.name }"
  >
    <div
      v-if="icon.name"
      class="info-left"
    >
      <SasIcon
        v-if="!icon.color"
        class="left__icon"
        :type="icon.name"
      />

      <SasIcon
        v-else
        class="left__icon"
        :stroke="icon.color"
        :type="icon.name"
      />
    </div>

    <div class="info-right">
      <p
        v-if="label"
        class="info-right__label"
      >
        {{ label }}
      </p>

      <div
        v-if="value.type"
        class="info-right__text--custom-tag"
        v-html="`<h${value.type}>${value.text}</h${value.type}>`"
      />

      <p
        v-else-if="value.text"
        class="info-right__text"
        v-html="value.text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SasInfo',

  props: {
    icon: {
      type: Object,
      validator: (value) => {
        const { name, color } = value
        const validTypes = (typeof name === 'string') && (typeof color === 'string')

        return validTypes
      },
      default: () => ({
        name: '',
        color: '',
      }),
    },

    label: {
      type: String,
      default: () => '',
    },

    value: {
      type: Object,
      validator: (value) => {
        const { text, type } = value
        const validTypes = (typeof text === 'string' || typeof text === 'number') && (typeof type === 'number')

        return validTypes
      },
      default: () => ({
        text: '',
        type: 0,
      }),
    },
  },
}
</script>

<style lang="sass">

.info
  display: grid
  grid-gap: 8px
  grid-template-columns: auto 1fr

  &--no-icon
    grid-template-columns: 1fr

  &-left
    display: flex
    align-items: center
    color: $color-ink-lighter

    .left__icon
      width: unset
      height: unset

  &-right
    display: flex
    flex-direction: column
    justify-content: center

    &__label
      font-size: 12px
</style>
