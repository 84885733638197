export default {
  data() {
    return {
      isScrollingUp: false,
      isScrollingDown: false,
      scrollPosition: 0,
    }
  },

  methods: {
    updateScroll() {
      const newScrollPosition = Math.abs(
        document.body.getBoundingClientRect().top,
      )
      if (newScrollPosition <= this.scrollPosition) {
        this.isScrollingUp = true
      } else {
        this.isScrollingUp = false
      }
      this.scrollPosition = Math.floor(newScrollPosition)
    },
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },

  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
}
