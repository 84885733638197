<template>
  <footer
    :class="[
      'exam-footer',
      { '--disabled': disabled },
    ]"
  >
    <ExamFooterPagination
      v-if="$mq === 'x_large' || $mq === 'large'"
      :disabled="disabled"
      :items="items"
      @finish="$emit('finish')"
      @next="redirectToNextItem()"
      @previous="redirectToPreviousItem()"
      @redirect="redirectToExamItem($event)"
      @show-questions="$emit('show-questions', $event)"
    />

    <ExamFooterPaginationMobile
      v-else
      :disabled="disabled"
      :items="items"
      @finish="$emit('finish')"
      @next="redirectToNextItem()"
      @previous="redirectToPreviousItem()"
      @redirect="redirectToExamItem($event)"
      @show-questions="$emit('show-questions', $event)"
    />
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

import ExamFooterPagination from '@/components/ExamFooterPagination'
import ExamFooterPaginationMobile from '@/components/ExamFooterPaginationMobile'

export default {
  name: 'ExamFooter',

  components: {
    ExamFooterPagination,
    ExamFooterPaginationMobile,
  },

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['items']),
  },

  methods: {
    redirectToExamItem(itemOrder) {
      if (this.disabled) {
        return
      }

      const { assessmentId, examId } = this.$route.params
      this.$router.replace({
        name: 'exam',
        params: {
          assessmentId,
          examId,
          itemOrder,
        },
      })
    },

    redirectToPreviousItem() {
      if (this.disabled) {
        return
      }

      const { itemOrder } = this.$route.params
      const previousOrder = parseInt(itemOrder, 10) - 1
      this.redirectToExamItem(previousOrder)
    },

    redirectToNextItem() {
      if (this.disabled) {
        return
      }

      const { itemOrder } = this.$route.params
      const nextOrder = parseInt(itemOrder, 10) + 1
      this.redirectToExamItem(nextOrder)
    },
  },
}
</script>

<style lang="sass">
.exam-footer
  +anim(200ms)
  backface-visibility: hidden

  &.--disabled
    pointer-events: none
</style>
