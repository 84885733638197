<template>
  <div>
    <SasModal
      :class="[
        'review-modal',
        { '--disabled': loading },
      ]"
      @close="$emit('close')"
    >
      <div
        slot="header"
        class="review-modal__header"
      >
        <h2 class="header__title">
          Finalizar Prova
        </h2>

        <div class="header__info">
          <div class="header-info__description">
            <p>
              Revise com atenção os itens que você marcou.
              Após finalizar você não poderá mais editar suas respostas.
            </p>
            <small>
              Clique no item para ir voltar à questão.
            </small>
          </div>

          <div class="header__info__group">
            <SasInfo
              v-if="showSpendTime"
              class="header__info__group__items"
              :icon="{
                name: 'clock',
                color: '',
              }"
              label="Tempo gasto"
              :value="{
                text: `${spendTime.hours}:${spendTime.minutes}`,
                type: 5,
              }"
            />

            <SasInfo
              class="header__info__group__items"
              :icon="{
                name: 'check-square',
                color: '',
              }"
              label="Questões respondidas"
              :value="{
                text: `${itemsAnsweredCount} de ${itemsCount}`,
                type: 5,
              }"
            />
          </div>
        </div>
      </div>

      <div
        slot="body"
        class="review-modal__body"
      >
        <div class="container-slider">
          <carousel
            :navigation-enabled="navigationEnabled"
            :pagination-active-color="colorGray"
            :pagination-color="colorLightGray"
            :per-page="1"
          >
            <slide
              v-for="(item, groupIndex) in answers"
              :key="groupIndex"
              :class="[
                'review-modal__body-details',
                {
                  '--simple': !manyQuestions,
                  '--custom': manyQuestions,
                },
              ]"
            >
              <div
                v-for="(answer, index) in item"
                :key="index"
                v-tooltip="{
                  content: `
                    <h5>Questão ${answer.studentOrder}</h5>
                    <p>${answer.wording}...</p>
                    `,
                  classes: ['answer__tooltip'],
                }"
                class="answer"
                :tabindex="index"
                @click="goToItem(answer.studentOrder)"
              >
                <h5 class="answer__order">
                  {{ answer.studentOrder }}
                </h5>

                <p
                  v-if="answer.id && answer.markedOption > -1"
                  class="answer__marked-option"
                >
                  {{ getOptionByOrder(answer.markedOption) }}
                </p>

                <p
                  v-else
                  class="answer--blank"
                >
                  Em branco
                </p>
              </div>
            </slide>
          </carousel>
        </div>
      </div>

      <div
        slot="footer"
        class="review-modal__footer"
      >
        <s-button
          size="large"
          variation="tertiary"
          @click="$emit('close')"
        >
          Cancelar
        </s-button>

        <s-button
          :disabled="loading"
          :loading="loading"
          size="large"
          @click="confirmationModal = true"
        >
          Finalizar Prova
        </s-button>
      </div>
    </SasModal>
    <Modal
      v-if="confirmationModal"
      class="confirmation-modal"
      width="470px"
      @close="closeModal()"
    >
      <div class="confirmation-modal__body">
        <h3>
          {{ reviewModalText }}
        </h3>
      </div>
      <div class="confirmation-modal__footer">
        <s-button
          size="large"
          variation="tertiary"
          @click="closeModal()"
        >
          {{ $t('exam.modalReview.buttonCancel') }}
        </s-button>
        <s-button
          :loading="loading"
          size="large"
          @click="endExam()"
        >
          {{ $t('exam.modalReview.buttonConfirm') }}
        </s-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SasInfo from '@/components/global/SasInfo'
import SasModal from '@/components/global/SasModal'

export default {
  name: 'ExamReviewModal',
  components: {
    SasInfo,
    SasModal,
  },

  props: {
    amountGroup: {
      type: Number,
      default: 30,
    },
  },

  data() {
    return {
      loading: false,
      answerGroup: [],
      currentGroup: 0,
      spendTime: {
        hours: '00',
        minutes: '00',
      },
      colorLightGray: '#C4C4C4',
      colorGray: '#666E75',
      confirmationModal: false,
    }
  },

  computed: {
    ...mapGetters([
      'items',
      'exam',
      'applicationResponse',
      'hasDeadline',
    ]),

    itemsCount() {
      return this.items.length
    },

    itemsAnsweredCount() {
      const answeredItems = this.items.filter(
        (item) => item.answer.markedOption >= 0,
      )

      return answeredItems.length
    },

    answers() {
      const answersItems = this.items.map((item, index) => {
        const { studentOrder, answer, wording } = item

        return {
          studentOrder,
          ...answer,
          wording,
          order: index,
        }
      })
      const answerGroup = []
      while (answersItems.length > 0) {
        answerGroup.push(
          answersItems.splice(0, this.amountGroup),
        )
      }

      return answerGroup
    },

    showSpendTime() {
      if (!this.hasDeadline) {
        return false
      }

      const { durationInMinutes } = this.exam

      return !(durationInMinutes === null && durationInMinutes <= 0)
    },

    manyQuestions() {
      const minQuestionsQty = 24

      return this.itemsCount > minQuestionsQty
    },

    navigationEnabled() {
      return this.$mq === 'large' || this.$mq === 'x_large'
    },

    answeredAllQuestions() {
      return this.itemsAnsweredCount === this.itemsCount
    },

    reviewModalText() {
      return this.answeredAllQuestions
        ? this.$t('exam.modalReview.text')
        : this.$t('exam.modalReview.confirmText', [
          this.itemsAnsweredCount,
          this.itemsCount,
        ])
    },

  },

  async created() {
    const hourInMinutes = 60
    const hourInSeconds = 3600
    try {
      this.loading = true

      const {
        remainingTimeInSeconds: timeLeft,
      } = await this.getTimeLeft(this.applicationResponse.id)

      const duration = Math.floor(this.exam.durationInMinutes * hourInMinutes)
      const time = duration - timeLeft

      this.spendTime = {
        hours: this.formatTime(
          Math.floor(Number(time) / hourInSeconds),
        ),
        minutes: this.formatTime(
          Math.floor((Number(time) % hourInSeconds) / hourInMinutes),
        ),
        seconds: this.formatTime(
          Math.floor((Number(time) % hourInSeconds) % hourInMinutes),
        ),
      }
    } catch (error) {
      this.$toasted.global.error({
        message: 'Erro ao tentar carregar tempo restante.',
      })
    } finally {
      this.loading = false
    }
  },

  updated() {
    const { offsetWidth } = document.getElementsByClassName('VueCarousel-dot-container')[0]
    if (offsetWidth) {
      document.getElementsByClassName('VueCarousel-pagination')[0].style.width = `${offsetWidth}px`
      const elementeNavigation = document.getElementsByClassName('VueCarousel-navigation')[0]
      if (elementeNavigation) {
        const additionalWidth = 20
        const navigation = offsetWidth + additionalWidth
        document.getElementsByClassName('VueCarousel-navigation')[0].style.width = `${navigation}px`
      }
    }
  },

  methods: {
    ...mapActions(['getTimeLeft']),
    getOptionByOrder(optionOrder) {
      const options = ['A', 'B', 'C', 'D', 'E']

      return options[optionOrder]
    },

    goToItem(newItemOrder) {
      const routeParams = this.$route.params
      this.$router.push({
        name: this.$route.name,
        params: {
          assessmentId: routeParams.assessmentId,
          applicationId: routeParams.applicationId,
          examId: routeParams.examId,
          itemOrder: newItemOrder,
        },
      })
      this.$emit('close')
    },

    endExam() {
      this.loading = true
      this.$emit('end', true)
    },

    setAnswerGroup(groupIndex) {
      const groupRef = this.$refs[`group-${groupIndex}`] || []

      if (groupRef.length > 0) {
        const { offsetLeft } = groupRef[0]
        const additionalWidth = 40

        this.$refs.answers.scrollLeft = offsetLeft - additionalWidth
        this.currentGroup = groupIndex
      }
    },

    nextGroup() {
      const groupIndex = this.currentGroup < (this.answers.length - 1)
        ? this.currentGroup + 1
        : this.answers.length - 1
      this.setAnswerGroup(groupIndex)
    },

    previousGroup() {
      const groupIndex = this.currentGroup > 0
        ? this.currentGroup - 1
        : 0
      this.setAnswerGroup(groupIndex)
    },

    formatTime(value) {
      const numberLength = 2

      return String(value).padStart(numberLength, '0')
    },

    closeModal() {
      this.confirmationModal = false
    },
  },
}
</script>

<style lang="sass">

.review-modal

  &--disabled
    pointer-events: none

  .modal
    padding: 0

    +mq_review_modal
      min-width: auto
      width: 100vw
      height: 100vh
      border-radius: 0
      overflow-y: auto
      scroll-behavior: smooth

    &__title
      padding: 40px
      padding-bottom: 24px

      +mq-m
        padding: 24px

      +mq-s
        padding: 16px

    &__footer
      margin: 0

  .header__info
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 8px
    color: $color-ink-light

    +mq-m
      flex-direction: column
      align-items: flex-start

    &__description
      box-sizing: border-box
      padding-right: 48px
      border-right: 1px solid transparentize($color-ink-lighter, .65)

      +mq-s
        padding: 0px
        border-right: none

      small
        margin-top: 8px
        display: block
        color: shade-color($color-ink-lighter, 1%)

    &__group
      display: flex
      min-width: 400px
      justify-content: flex-end

      +mq-m
        flex-direction: column
        justify-items: center
        align-items: flex-start
        min-width: 220px

      +mq-m
        flex-direction: row
        justify-content: space-between
        align-content: space-between
        min-width: 100%

      &__items
        flex-shrink: 0
        margin-left: 48px
        margin-right: 16px

        +mq-m
          margin-left: 0px
          margin-right: 0px
          margin-top: 32px

        .info-right__label
          opacity: .7

  &__body

    &-details

      box-sizing: border-box
      display: grid
      grid-gap: $size-s $size-xl

      grid-auto-flow: column
      padding: 0 $size-xl $size-l $size-xl
      padding-bottom: $size-l

      +mq-l
        grid-gap: $size-xs $size-m

      +mq-m
        grid-template-columns: repeat(3, 1fr)
        grid-template-rows: repeat(8, 1fr)
        padding: 0 $size-m $size-l $size-m

      +mq-s
        padding: $size-s
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(15, 1fr)
        grid-gap: $size-xs $size-s

      &.--simple
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: repeat(6, 1fr)

      &.--custom
        grid-template-columns: repeat(5, 1fr)
        grid-template-rows: repeat(6, 1fr)

        +mq-m
          grid-template-columns: repeat(2, 1fr)
          grid-template-rows: repeat(15, 1fr)

      .answer
        display: flex
        align-items: center
        background: tint-color($color-ink-lighter, 80%)
        border-radius: 6px
        padding: 12px 16px
        box-sizing: border-box
        cursor: pointer
        user-select: none
        backface-visibilty: hidden
        +anim(200ms)

        &:hover
          transform: scale(1.03)
          background: tint-color($color-ink-lighter, 25%)
          +anim(200ms)

        &:focus
          outline: 0
          transform: scale(1.03)
          box-shadow: 0 0 0 2px $color-primary inset

        &:active
          transform: scale(1.05)
          background: $color-ink-lighter
          +anim(200ms)

        &__order
          margin-right: 16px

        &__marked-option
          display: inline-flex
          border-radius: 100px
          width: 28px
          height: 28px
          align-items: center
          justify-content: center
          color: white
          background: $color-ink-light

        &--blank
          font-size: 14px
          color: transparentize($color-ink, .5)
          mix-blend-mode: multiply

  &__footer
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0px 32px 32px 32px

    +mq_review_modal
      position: fixed
      bottom: 0
      width: 100%
      left: 0
      background: white
      box-sizing: border-box
      box-shadow: 0 -1px 12px rgba(0,0,0,.1)
      padding: 8px 16px
      z-index: 100

    .btn--float
      text-decoration: none

    .btn ~ .btn
      min-width: 160px

.answer__tooltip .tooltip-inner
  padding: 16px

  h5
    line-height: 1
    margin-bottom: 6px
    font-size: 15px

  p
    font-size: 14px
    line-height: 1.4

.container-slider
  display: flex
  flex-direction: column
  padding-bottom: 96px

  .VueCarousel-navigation
    position: absolute
    bottom: 0
    height: 30px
    left: 0
    right: 0
    margin: 0 auto
    z-index: 0

    .VueCarousel-navigation-button
      font-size: 20px
      margin-top: -3px
      color: #C4C4C4
      opacity: 1
      outline: none

      &--disabled
        color: #C4C4C4
        opacity: 1

  .VueCarousel-pagination
    position: relative
    z-index: 1
    margin: 0 auto

    .VueCarousel-dot
      margin-top: 0 !important
      width: 12px !important
      height: 12px !important
      outline: none
.confirmation-modal
  .sas-modal__body
    padding: 0 32px 24px

  &__body
    h3
      font-size: $font-size-heading-6
      max-width: 85%
      padding-bottom: $size-m

  &__footer
    display: flex
    justify-content: space-between
</style>
