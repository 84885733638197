<template>
  <div
    v-if="showTimer"
    class="exam-timer"
    :class="{
      '--timer': active,
      '--notification': notification,
    }"
    @mouseenter="openTimer()"
    @mouseleave="active = false"
  >
    <div
      class="exam-timer__content-timer"
      :class="{
        '--active': active,
        '--notification': notification,
      }"
    >
      <icon
        class="exam-timer__content-timer__icon"
        :class="{
          '--animate': active,
          '--notification': notification,
        }"
        :size="sizeIcon"
        type="clock"
      />
      <div
        class="exam-timer__content-timer__time-left"
        :class="{
          '--timer': active,
          '--notification': notification,
        }"
      >
        {{ timeLeft.hours }}:{{ timeLeft.minutes
        }}<small>{{ timeLeft.seconds }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'ExamTimer',
  mixins: [mediaQueries],
  props: {
    alerts: {
      type: Array,
      default: () => [
        {
          time: 1800,
          icon: 'clock',
          message: 'Restam 30 minutos de prova.',
        },
      ],
    },
    enabladToaster: Boolean,
  },
  data() {
    return {
      timerId: null,
      active: false,
      assessmentTime: 0,
      notification: false,
      showTimer: false,
    }
  },
  computed: {
    ...mapGetters(['applicationResponse']),
    timeLeft() {
      const hourInSeconds = 3600
      const hourInMinutes = 60

      return {
        hours: this.formatTime(
          Math.floor(Number(this.assessmentTime) / hourInSeconds),
        ),
        minutes: this.formatTime(
          Math.floor(
            (Number(this.assessmentTime) % hourInSeconds) / hourInMinutes,
          ),
        ),
        seconds: this.formatTime(
          Math.floor(
            (Number(this.assessmentTime) % hourInSeconds) % hourInMinutes,
          ),
        ),
      }
    },
    sizeIcon() {
      return this.mq_m ? '25' : '35'
    },
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    ...mapActions(['getTimeLeft']),
    async startTimer() {
      try {
        const {
          remainingTimeInSeconds: timeLeft,
          deadline,
        } = await this.getTimeLeft(this.applicationResponse.id)

        this.showTimer = deadline !== null

        if (timeLeft <= 0 && deadline !== null) {
          this.$emit('end-time')
        } else {
          const delay = 1000

          this.assessmentTime = timeLeft
          this.timerId = setInterval(() => {
            this.assessmentTime -= 1
            this.showNotification()
            if (this.assessmentTime < 1 && deadline !== null) {
              this.stopTimer()
              this.active = true
              this.$emit('end-time')
            }
          }, delay)
        }
      } catch (error) {
        this.$toasted.global.error({
          message:
            'Erro ao iniciar a prova, por favor tente novamente mais tarde.',
        })
      }
    },
    stopTimer() {
      clearInterval(this.timerId)
    },
    openTimer() {
      this.active = true
      this.$emit('open-timer')
    },
    formatTime(value) {
      const numberLength = 2

      return String(value).padStart(numberLength, '0')
    },
    showNotification() {
      const index = this.alerts.findIndex((f) => f.time === this.assessmentTime)

      if (index > -1) {
        const delay = 5000
        this.active = true
        this.notification = true

        setTimeout(() => {
          this.active = false
          this.notification = false
        }, delay)
        if (this.enabladToaster) {
          this.$toasted.global.info({
            icon: this.alerts[index].icon || '',
            message: this.alerts[index].message || '',
          })
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.exam-timer
  display: flex
  justify-items: flex-start
  margin: 0 10px

  &:hover
    cursor: default

  &__content-timer
    background: transparent
    font-size: 28px
    position: relative

    display: flex
    flex-direction: row
    justify-content: center
    padding-left: 28px

    height: 35px
    width: 35px

    border: 3px solid $color-white
    border-color: $color-white
    border-radius: 30px

    transition: width 1.2s ease, color .3s linear

    +mq-m
      height: 25px
      width: 25px
      margin: -6px 10px 0 0
      font-size: 21px
      padding-left: 18px
      border-width: 1.5px

      border-color: #333a56

    &__time-left
      transition: opacity 0.2s linear

      line-height: 35px
      opacity: 0

      +mq-m
        line-height: 25px

      &.--timer
        transition: 0.7s opacity 0.5s ease-out
        opacity: 1

      small
        font-size: 18px
        padding: 0 2px

    &__icon
      transition: transform 1.2s ease
      width: 35px
      height: 35px
      position: absolute
      left: -3px
      top: -3px

      +mq-m
        width: 25px
        height: 25px
        left: -1px
        top: -2px

      +mq-s
        width: 25px
        height: 25px
        left: -2px
        top: -1px

      &.--animate
        transform: rotate( 1turn )

    &.--active
      width: 145px

      +mq-m
        width: 115px

  .--notification
    color: $color-warning
    border-color: $color-warning
</style>
