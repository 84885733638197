<template>
  <svg
    class="progress-bar"
    :height="height"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      class="progress-bar__background"
      :fill="background"
      height="100%"
      :rx="radius"
      :ry="radius"
      width="100%"
    />
    <rect
      class="progress-bar__foreground"
      :fill="color"
      height="100%"
      :rx="radius"
      :ry="radius"
      :width="`${progress}%`"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    total: {
      type: Number,
    },
    value: {
      type: Number,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
    radius: {
      type: [Number, String],
      default: 4,
    },
  },

  computed: {
    progress() {
      const percentModifier = 100
      const decimalPlaces = 2
      const percent = (this.value / this.total) * percentModifier
      if (percent >= percentModifier) {
        return percent
      }

      return percent.toFixed(decimalPlaces)
    },
  },
}
</script>

<style lang="sass">

.progress-bar
  position: relative

  &__background,
  &__foreground
    transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1)

  &__background
    fill: $color-ink-lightest

</style>
