var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SasModal',{class:[
      'review-modal',
      { '--disabled': _vm.loading } ],on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"review-modal__header",attrs:{"slot":"header"},slot:"header"},[_c('h2',{staticClass:"header__title"},[_vm._v(" Finalizar Prova ")]),_c('div',{staticClass:"header__info"},[_c('div',{staticClass:"header-info__description"},[_c('p',[_vm._v(" Revise com atenção os itens que você marcou. Após finalizar você não poderá mais editar suas respostas. ")]),_c('small',[_vm._v(" Clique no item para ir voltar à questão. ")])]),_c('div',{staticClass:"header__info__group"},[(_vm.showSpendTime)?_c('SasInfo',{staticClass:"header__info__group__items",attrs:{"icon":{
              name: 'clock',
              color: '',
            },"label":"Tempo gasto","value":{
              text: ((_vm.spendTime.hours) + ":" + (_vm.spendTime.minutes)),
              type: 5,
            }}}):_vm._e(),_c('SasInfo',{staticClass:"header__info__group__items",attrs:{"icon":{
              name: 'check-square',
              color: '',
            },"label":"Questões respondidas","value":{
              text: (_vm.itemsAnsweredCount + " de " + _vm.itemsCount),
              type: 5,
            }}})],1)])]),_c('div',{staticClass:"review-modal__body",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"container-slider"},[_c('carousel',{attrs:{"navigation-enabled":_vm.navigationEnabled,"pagination-active-color":_vm.colorGray,"pagination-color":_vm.colorLightGray,"per-page":1}},_vm._l((_vm.answers),function(item,groupIndex){return _c('slide',{key:groupIndex,class:[
              'review-modal__body-details',
              {
                '--simple': !_vm.manyQuestions,
                '--custom': _vm.manyQuestions,
              } ]},_vm._l((item),function(answer,index){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: ("\n                  <h5>Questão " + (answer.studentOrder) + "</h5>\n                  <p>" + (answer.wording) + "...</p>\n                  "),
                classes: ['answer__tooltip'],
              }),expression:"{\n                content: `\n                  <h5>Questão ${answer.studentOrder}</h5>\n                  <p>${answer.wording}...</p>\n                  `,\n                classes: ['answer__tooltip'],\n              }"}],key:index,staticClass:"answer",attrs:{"tabindex":index},on:{"click":function($event){return _vm.goToItem(answer.studentOrder)}}},[_c('h5',{staticClass:"answer__order"},[_vm._v(" "+_vm._s(answer.studentOrder)+" ")]),(answer.id && answer.markedOption > -1)?_c('p',{staticClass:"answer__marked-option"},[_vm._v(" "+_vm._s(_vm.getOptionByOrder(answer.markedOption))+" ")]):_c('p',{staticClass:"answer--blank"},[_vm._v(" Em branco ")])])}),0)}),1)],1)]),_c('div',{staticClass:"review-modal__footer",attrs:{"slot":"footer"},slot:"footer"},[_c('s-button',{attrs:{"size":"large","variation":"tertiary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancelar ")]),_c('s-button',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"size":"large"},on:{"click":function($event){_vm.confirmationModal = true}}},[_vm._v(" Finalizar Prova ")])],1)]),(_vm.confirmationModal)?_c('Modal',{staticClass:"confirmation-modal",attrs:{"width":"470px"},on:{"close":function($event){return _vm.closeModal()}}},[_c('div',{staticClass:"confirmation-modal__body"},[_c('h3',[_vm._v(" "+_vm._s(_vm.reviewModalText)+" ")])]),_c('div',{staticClass:"confirmation-modal__footer"},[_c('s-button',{attrs:{"size":"large","variation":"tertiary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t('exam.modalReview.buttonCancel'))+" ")]),_c('s-button',{attrs:{"loading":_vm.loading,"size":"large"},on:{"click":function($event){return _vm.endExam()}}},[_vm._v(" "+_vm._s(_vm.$t('exam.modalReview.buttonConfirm'))+" ")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }