<template>
  <div>
    <transition name="moveDown">
      <header
        v-if="(isScrollingUp || scrollPosition < 10 || alert.message)"
        class="exam-header"
      >
        <div class="exam-header__title">
          <p class="exam-header__title__assessment">
            {{ assessment.name }}
          </p>

          <h3
            id="v-step-4"
            class="exam-header__title__exam"
          >
            {{ exam.name }}
          </h3>
        </div>

        <div
          id="v-step-5"
          class="exam-header__actions"
        >
          <ExamTimer
            v-if="!mq_m && hasDeadline"
            @end-time="endTime()"
            @open-timer="$emit('open-timer')"
          />

          <!-- <SSwitchDarkMode
            class="exam-header__switch-dark-mode"
            :value="darkModeEnabled"
            @toggle="$emit('toggleDarkMode')"
            /> -->

          <s-button
            v-if="showButtonPause && !mq_m"
            id="v-step-6"
            class="exam-header__actions__pause"
            color="white"
            :disabled="disabled"
            icon-left="pause-circle"
            size="large"
            variation="tertiary"
            @click="$emit('pause')"
          >
            Pausar e sair
          </s-button>

          <s-button
            v-if="!mq_m"
            id="v-step-7"
            class="exam-header__actions__finish"
            :disabled="disabled"
            icon-left="check-circle"
            size="large"
            variation="secondary"
            @click="$emit('finish')"
          >
            Revisar e finalizar prova
          </s-button>
        </div>

        <Popover
          v-if="mq_m"
          id="v-step-5"
          class="exam-header__actions--small"
          :disabled="disabled"
          :hide-disabled="hideMenuDisabled"
          :index="3"
          :toggle="{ theme: 'float-dark' }"
        >
          <s-button
            slot="toggleIcon"
            color="white"
            icon="more-horizontal"
            size="large"
            variation="tertiary"
          />

          <s-button
            slot="menu"
            class="actions__button"
            icon-left="pause-circle"
            size="large"
            variation="tertiary"
            @click.native="$emit('pause')"
          >
            Pausar e sair
          </s-button>

          <s-button
            id="v-step-7"
            slot="menu"
            class="actions__button"
            icon-left="check-circle"
            size="large"
            theme="float"
            variation="tertiary"
            @click.native="$emit('finish')"
          >
            Revisar e finalizar prova
          </s-button>
        </Popover>
      </header>
    </transition>

    <div
      v-if="alert.message"
      class="header__alert"
      :class="{
        '--success': alert.success,
        '--fail': !alert.success,
      }"
    >
      <SasInfo
        :icon="{
          name: alert.icon,
          color: 'white',
        }"
        :value="{
          text: alert.message,
          type: 0,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Popover from '@/components/Popover'
import ExamTimer from '@/components/ExamTimer'
// import SSwitchDarkMode from '@/components/SSwitchDarkMode'
import SasInfo from '@/components/global/SasInfo'

import scrollHandler from '@/mixins/scrollHandler'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'ExamHeader',

  components: {
    Popover,
    ExamTimer,
    // SSwitchDarkMode,
    SasInfo,
  },

  mixins: [scrollHandler, mediaQueries],

  props: {
    alert: {
      type: Object,
      required: true,
      default: () => ({
        message: '',
        icon: '',
        success: false,
      }),
    },

    disabled: {
      type: Boolean,
      required: true,
    },

    hideMenuDisabled: Boolean,
  },

  computed: {
    ...mapGetters([
      'assessment',
      'exam',
      'item',
      'darkModeEnabled',
      'hasDeadline',
    ]),
    scrolled() {
      const scrolledValue = 40

      return this.scrollPosition < scrolledValue
        && this.item.studentOrder === 1
    },
    showButtonPause() {
      const { durationInMinutes } = this.exam

      return durationInMinutes === null && durationInMinutes <= 0
    },
  },
  methods: {
    endTime() {
      this.$emit('end-time')
    },
  },
}
</script>

<style lang="sass">

.exam-header
  position: fixed
  color: white
  width: 100%
  top: 0
  z-index: 999
  padding: 0 48px
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  height: 64px
  background: #5196D6
  +anim(300ms)

  +mq-l
    padding: 0 32px

  +mq-m
    padding: 0 24px

  +mq-s
    height: 64px
    padding: 0 16px

  &__title
    &__assessment
      margin-bottom: 2px
      opacity: .85
      font-size: 14px
      +anim(300ms)

      +mq-s
        font-size: 13px

    &__exam
      font-size: 20px
      +anim(300ms)

      +mq-s
        font-size: 18px

  &__switch-dark-mode
    margin: 0 19px 0 10px

  // Actions
  &__actions--small

    &.--scroll
      display: none

    .actions__button
      background: white
      margin: 0 16px 0 22px

  &__actions
    display: flex
    align-items: center

    &__pause
      +mq-s
        display: none

    .btn ~ .btn
      margin-left: 8px

.header__alert
  position: fixed
  display: flex
  justify-content: center
  padding: 16px
  box-sizing: border-box
  z-index: 998
  color: white
  width: 100%
  top: 64px

  +mq-s
    padding: 8px 16px
    justify-content: start
    top: 64px

    p
      font-size: 13px

  &.--fail
    background: $color_danger

  &.--success
    background: $color_success

</style>
