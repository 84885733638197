<template>
  <section
    v-if="items.length"
    key="item-section"
    class="exam-pagination"
  >
    <Dropdown
      class="exam-pagination__pages__list"
      :disabled="disabled"
      icon="chevron-down"
      origin="top-left"
      size="large"
      text="Ver todas as questões"
      variation="secondary"
      @toggle="$emit('show-questions', $event)"
    >
      <dropdown-menu-item>
        <div class="exam-pagination__pages">
          <button
            v-for="item in items"
            :key="item.questioId"
            :class="[
              'exam-pagination__item',
              {
                'exam-pagination__item--actived': item.answer
                  && item.answer.markedOption > -1,
                'exam-pagination__item--filled': isSameItem(item.studentOrder),
              },
            ]"
            :disabled="disabled || isAnswering"
            @click="$emit('redirect', item.studentOrder)"
          >
            {{ item.studentOrder }}
          </button>
        </div>
      </dropdown-menu-item>
    </Dropdown>

    <div class="exam-pagination__actions">
      <s-button
        class="exam-pagination__actions__previous"
        :disabled="previousDisabled || disabled || isAnswering"
        icon-left="arrow-left"
        size="large"
        variation="secondary"
        @click="$emit('previous')"
      >
        {{ $t('exam.footerPagination.previousButton') }}
      </s-button>
      <s-input
        v-model="selectedItemOrder"
        class="exam-pagination__actions__page"
        :maxlength="2"
        size="large"
        type="number"
        @blur="$emit('redirect', selectedItemOrder)"
        @enter="$emit('redirect', selectedItemOrder)"
      />
      <s-button
        v-if="!nextDisabled"
        :disabled="nextDisabled || disabled || isAnswering"
        icon-right="arrow-right"
        size="large"
        @click="$emit('next')"
      >
        {{ $t('exam.footerPagination.nextButton') }}
      </s-button>
      <s-button
        v-if="nextDisabled"
        id="v-step-7"
        :disabled="disabled"
        icon-left="check-circle"
        size="large"
        variation="secondary"
        @click="$emit('finish')"
      >
        {{ $t('exam.footerPagination.reviseButton') }}
      </s-button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExamFooterPagination',

  props: {
    items: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedItemOrder: 0,
    }
  },

  computed: {
    ...mapGetters(['isAnswering']),

    itemOrder() {
      return parseInt(this.$route.params.itemOrder, 10)
    },
    previousDisabled() {
      const [student] = this.items
      const { studentOrder } = student

      return this.itemOrder <= studentOrder
    },
    nextDisabled() {
      const { studentOrder } = this.items[this.items.length - 1]

      return this.itemOrder >= studentOrder
    },
    isSameItem() {
      return (studentOrder) => parseInt(
        this.itemOrder, 10,
      ) === parseInt(
        studentOrder, 10,
      )
    },
  },

  watch: {
    itemOrder(value) {
      this.selectedItemOrder = value
    },
  },

  created() {
    const { itemOrder } = this.$route.params
    this.selectedItemOrder = itemOrder
  },
}
</script>

<style lang="sass">

.exam-pagination
  display: flex
  overflow: auto
  z-index: 100
  align-items: center
  padding: 8px 32px
  box-sizing: border-box
  background: $color-ink-lightest
  position: fixed
  width: 100%
  height: 64px
  right: 0
  bottom: 0
  justify-content: flex-end
  +anim(200ms)
  backface-visibility: hidden

  &__item
    flex-shrink: 0
    display: inline-block
    font-size: 12px
    font-weight: 600
    text-align: center
    color: $color-ink-light
    width: 32px
    height: 28px
    line-height: 23px !important
    border-radius: 5px
    padding: 0
    background: transparent
    border: 1px solid transparentize($color-ink-light, 0.6)
    cursor: pointer
    +anim(200ms)

    &--actived
      +anim(150ms)
      color: $color-ink
      background: transparentize($color-ink-light, 0.5)

    &--filled
      +anim(150ms)
      color: white
      background: $color-primary

    &:hover
      +anim(150ms)
      transform: scale(1.06)

    &:focus
      outline: 0
      box-shadow: 0 0 0 2px transparentize($color-primary, .1)

  &__pages
    width: 100%
    display: grid
    align-items: center
    grid-template-columns: repeat(10, 1fr)
    grid-gap: 8px
    margin-right: 16px

    +mq-l
      grid-gap: 6px

    &__list
      position: fixed
      left: $element-size-l

      .sas-dropdown-menu
        background: $color-ice !important

        &__item
          background: $color-ice !important

          &:hover
            background: $color-ice
  &__actions
    display: flex
    align-items: center

    &__previous
      margin-right: $size-s

    &__page
      margin-right: $size-s
      width: 57px

      .sas-input__field
        text-align: center
        background: $color-white
        -webkit-appearance: none
        margin: 0
        padding: 0 14px

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
          -webkit-appearance: none
          margin: 0

    .btn
      cursor: pointer

    .btn ~ .btn
      margin-left: 8px
</style>
