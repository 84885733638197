<template>
  <div
    class="popover"
    :class="{ '--disabled': disabled }"
  >
    <SasButton
      class="popover__toggle"
      :size="toggle.size"
      :theme="toggle.theme"
      @click.native="showMenu()"
    >
      <slot name="toggle" />

      <slot
        slot="icon"
        name="toggleIcon"
      />
    </SasButton>

    <transition name="dropdown">
      <Box
        v-if="isMenuVisible"
        :id="`popover_${index}`"
        class="popover__menu"
        :class="`popover__menu--${direction}`"
        elevation="3"
        padding="8px 0"
        :tabindex="index"
        @blur.native="hideMenu()"
      >
        <slot name="menu" />
      </Box>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Popover',

  props: {
    index: {
      type: [Number, String],
      required: true,
    },

    toggle: {
      type: Object,
      required: true,
      default: () => ({
        size: '',
        theme: '',
      }),
    },

    direction: {
      type: String,
      default: 'right',
    },

    disabled: {
      type: Boolean,
      required: true,
    },

    hideDisabled: Boolean,
  },

  data() {
    return {
      isMenuVisible: false,
    }
  },

  methods: {
    showMenu() {
      if (this.disabled) {
        return
      }
      this.isMenuVisible = true
      this.$nextTick(() => document.getElementById(`popover_${this.index}`).focus())
    },

    hideMenu() {
      if (this.hideDisabled) {
        return
      }
      this.isMenuVisible = false
    },
  },
}
</script>

<style lang="sass">
.popover
  display: inline-block
  width: max-content
  position: relative

  &.--disabled
    pointer-events: none

  &__menu
    position: absolute
    top: calc(100% - 4px)
    width: max-content
    display: flex
    align-items: flex-start
    flex-direction: column

    &:focus
      outline: 0

    &--right
      right: 0

    &--left
      left: 0

    .btn
      border-radius: 0 !important
      padding: 12px 20px !important
      height: auto !important

    .btn ~ .btn
      border-top: 1px solid $color-ink-lightest !important

</style>
