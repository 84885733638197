var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showTimer)?_c('div',{staticClass:"exam-timer",class:{
    '--timer': _vm.active,
    '--notification': _vm.notification,
  },on:{"mouseenter":function($event){return _vm.openTimer()},"mouseleave":function($event){_vm.active = false}}},[_c('div',{staticClass:"exam-timer__content-timer",class:{
      '--active': _vm.active,
      '--notification': _vm.notification,
    }},[_c('icon',{staticClass:"exam-timer__content-timer__icon",class:{
        '--animate': _vm.active,
        '--notification': _vm.notification,
      },attrs:{"size":_vm.sizeIcon,"type":"clock"}}),_c('div',{staticClass:"exam-timer__content-timer__time-left",class:{
        '--timer': _vm.active,
        '--notification': _vm.notification,
      }},[_vm._v(" "+_vm._s(_vm.timeLeft.hours)+":"+_vm._s(_vm.timeLeft.minutes)),_c('small',[_vm._v(_vm._s(_vm.timeLeft.seconds))])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }